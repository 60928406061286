import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import { Slide } from 'react-slideshow-image';

import "../styles/community.css"

import slide_1 from '../images/community/slide_1.jpg';
import slide_2 from '../images/community/slide_2.jpg';
import slide_3 from '../images/community/slide_3.jpg';
import slide_4 from '../images/community/slide_4.jpg';
import slide_5 from '../images/community/slide_5.jpg';
import slide_6 from '../images/community/slide_6.jpg';

import fall_city_newsletter from '../assets/12-december-2009final-nl-request.pdf'


// const slideImages = [
//   slide_1,
//   slide_2,
//   slide_3,
//   slide_4,
//   slide_5,
//   slide_6
// ];

// const properties = {
//   duration: 3000,
//   transitionDuration: 500,
//   infinite: true,
//   indicators: false,
//   arrows: false,
//   pauseOnHover: true,
// }

// const Slideshow = () => {
//     return (
//       <div className="slide-container">
//         <Slide {...properties}>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[3]})`}}>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[4]})`}}>
//             </div>
//           </div>
//           <div className="each-slide">
//             <div style={{'backgroundImage': `url(${slideImages[5]})`}}>
//             </div>
//           </div>
//         </Slide>
//       </div>
//     )
// }

const Community = () => (
  <Layout>
    <SEO title="Community" />
    <br/>
    <h1>Community</h1>
    <p>In 1885 the Baxter’s were recognized as the “Center of the Community”</p>
    {/* <Slideshow/> */}
    <div>
        <br/>
        <a href={fall_city_newsletter} target="_blank" rel="noopener noreferrer">Fall City Neighbors Newsletter</a>
    </div>
    <div>
        <br/>
        <iframe title="Fall City Profile" width="420" height="315" src="https://www.youtube.com/embed/OUggwRSohdY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div>
        <br/>
        <p><strong>Places to Stay for the Night (Stay in Fall City and visit Baxter Barn):</strong></p>
        <p><a href="http://www.fcroadhouse.com/index.html" target="_blank" rel="noopener noreferrer">Fall City Road House</a></p>
        <p><a href="http://treehousepoint.com/" target="_blank" rel="noopener noreferrer">Tree House Point</a></p>
        <p><a href="http://srcghsg.com/" target="_blank" rel="noopener noreferrer">Snoqualmie River RV Park and Campground</a></p>
    </div>
    <div>
        <br/>
        <p><strong>Places to Eat in the Valley (Visit Baxter Barn before or after a great meal):</strong></p>
        <p><a href="http://www.fcroadhouse.com/index.html" target="_blank" rel="noopener noreferrer">Fall City Road House</a></p>
        <p><a href="http://woodmanlodge.com/" target="_blank" rel="noopener noreferrer">Woodman Lodge</a></p>
    </div>
  </Layout>
)

export default Community
